<template>
  <div id="app" class="container">
        <voteAdmin />
  </div>
</template>

<script>
import voteAdmin from './components/voteAdmin.vue'

export default {
  name: 'App',
  components: {
    voteAdmin
  }
}
</script>

<style>
#app {
  font-family: 'Literata', serif;

@import url('https://fonts.googleapis.com/css2?family=Literata&display=swap');


}
</style>
