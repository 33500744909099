<template>
    <div class="text-center">
        <h1></h1>
        <img src="../assets/trident.png" alt="тризуб" style="max-width: 15mm">
        <h3>УКРАЇНА <br>
            <strong>ГОРОДОЦЬКА МІСЬКА РАДА</strong> <br>
            ЛЬВІСЬКОЇ ОБЛАСТІ</h3>
        <h4>ПОІМЕННЕ ГОЛОСУВАННЯ ПО РІШЕННЮ</h4>
        <p v-if="!isFetching">
            <select name="" id="" class="form-control" v-model="vote.pr_id">
          <option v-for="pr in prList.filter(item => item.voted == false)" :value="pr.id" :key="pr.id">{{ pr.num_pr}} {{ pr.about}}</option>

        </select></p>
        <div class="d-flex flex-row justify-content-around">
            <h4 class=" px-2 badge-success" >ЗА {{ sumVotes(1)}} </h4>
            <h4 class=" px-2 badge-danger" >ПРОТИ {{ sumVotes(2)}} </h4>
            <h4 class=" px-2 badge-warning" >Утр.{{ sumVotes(3)}} </h4>
            <h4 class=" px-2 badge-info" >Не гол.{{ sumVotes(0)}} </h4>
            <h4 class=" px-2 badge-dark" >Відсутні {{ sumVotes(4)}} </h4>


        </div>
        <div class="d-flex flex-row justify-content-center">

            <h2 class="bg-success text-light px-2 mx-1" v-show="sumVotes(1) > 17"> РІШЕННЯ УХВАЛЕНО </h2>
            <button class="btn btn-info mx-2" @click="sendVotes"> Надіслати інформацію на сервер </button>
        </div>
        <table class="table table-bordered" v-if="!isFetching">
            <thead>
            <tr>
                <th>##</th>
                <th>Ім'я депутата</th>
                <th>Голосував</th>
                <th style="min-width: 246px">Результат</th>
            </tr>
            </thead>
            <tbody v-for="(dep,index) in deputats" :key="dep.id">
            <tr :class="absent(vote.results[index])">
                <td>{{ index+1}}</td>
                <td> {{ dep.name }}
                    <input type="radio" v-model.number="vote.results[index]" value="4"></td>
                <td ><div class="form-check form-check-inline">
                    <label class="btn  btn-success btn-sm" >
                        <input class="form-check-input" type="radio" v-model.number="vote.results[index]" value="1">
                        За</label>
                </div>
                    <div class="form-check form-check-inline">
                        <label class="btn  btn-danger btn-sm" >
                            <input class="form-check-input" type="radio" v-model.number="vote.results[index]" value="2">
                            Проти</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="btn  btn-info btn-sm" >
                            <input class="form-check-input" type="radio" v-model.number="vote.results[index]" value="3">
                            Утримався</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="btn  btn-default btn-sm" >
                            <input class="form-check-input" type="radio" v-model.number="vote.results[index]" value="0">
                            не голосував</label>
                    </div>
                </td>
                <td>
                    {{ res(vote.results[index]) }}
                </td>
            </tr>
<!--            <dep-vote :dep="dep"></dep-vote>-->
            </tbody>
        </table>
    </div>
</template>

<script>
    //import depVote from "./depVote";

    export default {
        name: 'voteAdmin',
       // components: {depVote},
        data() {
            return {
                isFetching:true,
                deputats: [
                    {name: 'Лупій Микола', vote: 0, id: 17,status:1},
                    {name: 'Адаменко Віктор', vote: 0, id: 1,status:1}
                ],
                prList:[{id:1,about:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam, aspernatur aut dolorem hic itaque laudantium natus numquam odio',voted: false}],
                vote:{
                    pr_id:0,
                    results:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                }
            }
        },
        mounted() {
            this.getPrs();
            //this.getVote();
        },
        methods: {
            getDeputats() {
                this.$http.post(this.$URL,{r:'deputats'})
                    .then(resp => {
                        console.log(resp);
                        this.deputats = resp.data;
                        this.getVote();
                    }).then(() =>this.isFetching = false).catch(err=> console.log(err));
            },
            getPrs(){
                this.$http.post(this.$URL,{r:'prs',session_num:5})
                    .then(resp => {
                        this.prList = resp.data;
                        console.log(resp);
                        this.getDeputats();


                    }).catch(err=> console.log(err));
            },
            getVote() {
                console.log(this.deputats);
                //this.vote = [...this.deputats.in_table];
                console.log(this.vote);
            },

                res(a) {

                    if(a == 1) {
                        return 'ЗА'
                    } else {
                        if (a == 2) {
                            return 'ПРОТИ'
                        } else {
                            if (a == 3) {
                                return 'УТРИМАВСЯ'
                            } else { if(a==0) {
                                return 'НЕ ГОЛОСУВАВ'
                            } else {
                                return 'ВІДСУТНІЙ'}

                            }
                        }
                    }
            },
            absent(a){
                if (a ==4) {
                    return 'disabled'
                } else return ''
            },
            blocked(a) {
                if (a ==4) {
                    return 'blocked'
                } else return ''
            },
            sumVotes(a){

                return this.vote.results.filter((x)=> x==a).length
            },
            sendVotes(){

                this.$http.post(this.$URL,{r:'saveVote',vote:this.vote}).then(resp => {
                    console.log(resp.data);
                    if(resp.data[0] == true) {
                        //this.getPrs();
                    }
                    alert(resp.data[1])
                }).catch(err=>console.log(err))
            }
        },
    computed: {}


    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    tr td{
        padding: 0 !important;
        margin: 0 !important;
    }

    .disabled{
        /*pointer-events: none;*/
        background-color: lightgray;
    }
    .blocked{
        pointer-events: none;
    }

</style>
